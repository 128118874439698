<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <output
      class="total_amount"
      :style="style"
      v-dompurify-html="value"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTotalAmount',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      if (!Array.isArray(this.dataViewer.value)) {
        const matrix = this.cell.correlation_matrix
        return builder.calculateTotalAmount(index, matrix)
      }
      return builder.getCellValue(index, this.cell, this.clone, '0')
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content .total_amount {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
</style>
